import React from 'react'
import './header.css'
import CTA from './CTA'
import 'animate.css'
import ME from '../../assets/me.png'
import HeaderSocial from './HeaderSocials'

const Header = () => {
  return (
    <header>
      <div className="container header__container">
        <div className="animate__animated animate__fadeInDown">
          <h5>Hello I'm</h5>
          <h1>Mouad ELBOUCHRAOUI</h1>
          <h5 className="text-light">Software Engineer</h5>
         
        <CTA />
        </div>
        <HeaderSocial/>
        <div className="me animate__animated animate__fadeInDown animate__faster">
          <img src={ME} alt="me"/>
        </div>

        <a href="#contact" className='scroll__down'>Scroll Down</a>
      </div>
    </header>
  )
}

export default Header